import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Anchor, Question } from '../../App.styles';
import { useTranslation } from 'react-i18next';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import { AppOutcome, StepKey } from '../../types';
import { LoadingIndicator, NavFooter, InfoBox, Button } from '../';
import {
  FillGrid,
  ArrowButton,
  Container,
  Description,
  SymbolCheckbox,
  PortLabelsContainer,
  ExtraSpace,
} from './NamingConventions.styles';
import {
  MetricProperty,
  TestId,
  formatTerminalLabels,
  getModelOutcome,
  iconUrl,
  isAmazonHost,
  metricEvents,
} from '../../utils';
import {
  BrandsContext,
  CandidateModelsContext,
  StepperContext,
  StepperContextData,
} from '../../contexts';
import { ModelsListResponse, listModels } from '../../api';
import ExampleModal, { ImageExample } from '../ExampleModal/ExampleModal';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface NamingConventionsProps {
  next: (customData?: Partial<StepperContextData>) => void;
  onBack: () => void;
}

type FormatNamingConvention = {
  id: string;
  label: string;
};

const NC_LENGTH = 9;

const NamingConventions: FunctionComponent<NamingConventionsProps> = ({
  next,
  onBack,
}) => {
  const { t } = useTranslation('common');
  const navFooter = useRef<HTMLDivElement>(null);
  const [loading, setLoading] = useState(true);
  const [namingConventions, setNamingConventions] = useState<
    FormatNamingConvention[]
  >([]);
  const [allNamingConventions, setAllNamingConventions] = useState<
    FormatNamingConvention[]
  >([]);
  const [selected, setSelected] = useState<FormatNamingConvention[]>([]);
  const [selectionChanged, setSelectionChanged] = useState<boolean>(false);
  const [listModelsData, setListModelsData] = useState<ModelsListResponse>();
  const [showModal, setShowModal] = useState(false);
  const [exampleImage, setExampleImage] = useState<ImageExample>();
  const { handleOutcome } = useHandleOutcome();
  const { track } = useMetrics();
  const { brands } = useContext(BrandsContext);
  const { updateData } = useContext(CandidateModelsContext);
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brand, multiDoor, isParallel } = stepperData;

  const handleModelsOutcome = useCallback(
    (outcome: AppOutcome, terminals: string[], reason: ReasonOutcomeType) => {
      handleOutcome({
        value: outcome,
        screen: ScreenOutcomeType.SelectTerminals,
        reason,
        brand,
        terminals,
      });
    },
    [brand, handleOutcome, selected]
  );

  useEffect(() => {
    if (brand && selectionChanged) {
      if (selected.length === 0) {
        setListModelsData(undefined);
      } else if (multiDoor !== undefined && isParallel !== undefined) {
        setSelectionChanged(false);
        const list = selected.map((sel) => sel.label);
        listModels(brand, list, multiDoor, isParallel)
          .then((val) => {
            setListModelsData(val);
            // If we have estimated support status, navigate without waiting for user to click continue
            if (val.estimated_support_status) {
              handleModelsOutcome(
                getModelOutcome(val.estimated_support_status),
                list,
                ReasonOutcomeType.EarlyResult
              );
            }
          })
          .catch(() => {
            setListModelsData(undefined);

            // TODO: only do this for 404 and 409. Need CORS error fixed in back end
            handleModelsOutcome(
              AppOutcome.InfoNeeded,
              list,
              ReasonOutcomeType.APIError
            );
          });
      }
    }
  }, [
    brand,
    isParallel,
    multiDoor,
    selected,
    selectionChanged,
    handleModelsOutcome,
  ]);

  useEffect(() => {
    if (brands) {
      const data = brands.find((br) => br.brand_name === brand);
      if (data && data.terminal_labels) {
        const list = formatTerminalLabels(data.terminal_labels);
        setAllNamingConventions(list);
        setNamingConventions(list.slice(0, NC_LENGTH));
      } else {
        setNamingConventions([]);
      }

      setLoading(false);
    }
  }, [brand, brands]);

  const handleItem = (item: FormatNamingConvention) => {
    track(metricEvents.terminalSelected, {
      [MetricProperty.selectedBrand]: brand,
      [MetricProperty.selectedTerminal]: item.label,
    });
    const index = selected.findIndex((el) => el.id === item.id);

    if (index !== -1) {
      const newArr = selected.filter((el) => el.id !== item.id);
      setSelected(newArr);
    } else {
      setSelected((selected) => [...selected, item]);
    }
    setSelectionChanged(true);
  };

  const isSelected = (item: FormatNamingConvention) => {
    return selected.some((el) => el.id === item.id);
  };

  const handleContinue = () => {
    const list = selected.map((sel) => sel.label);
    listModelsData?.candidates.push({
      model_name: 'none',
      support: 'possible_support',
      terminal_labels: ['None of these'],
    });
    updateData && updateData(listModelsData);
    updateStepperData({ terminals: list });
    next({ terminals: list });
  };

  const handleTerminalsOutcome = (reason: ReasonOutcomeType) => {
    handleOutcome({
      value: AppOutcome.InfoNeeded,
      screen: ScreenOutcomeType.SelectTerminals,
      brand,
      reason,
    });
  };

  const handleLoadAll = () => {
    setNamingConventions(allNamingConventions);
    track(metricEvents.loadAllBtn, {
      [MetricProperty.pageName]: StepKey.NamingConventions,
    });
  };

  const handleNoTerminals = () => {
    track(metricEvents.noTerminalsBtn, {
      [MetricProperty.pageName]: StepKey.NamingConventions,
    });
    handleTerminalsOutcome(ReasonOutcomeType.TerminalsNotInList);
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.NamingConventions,
    });
    handleTerminalsOutcome(ReasonOutcomeType.HelpClick);
  };

  const handleExampleClick = (image: ImageExample) => {
    setExampleImage(image);
    setShowModal(true);
    track(metricEvents.exampleBtn, {
      [MetricProperty.pageName]: StepKey.NamingConventions,
      [MetricProperty.choice]: image,
    });
  };

  const examplesLinks = [
    { image: ImageExample.Example1, text: t('exampleModal.example1') },
    { image: ImageExample.Example2, text: t('exampleModal.example2') },
    { image: ImageExample.Example3, text: t('exampleModal.example3') },
  ];

  return (
    <div
      className="row h-100 flex-column"
      data-testid={TestId.NamingConventions}
    >
      <Question className="col-12">{t('prompts.namingConventions')}</Question>
      <Description>{t('prompts.namingConventionsDescription')}</Description>

      <div className="col-12 my-3">
        <InfoBox
          title={t('help.namingConventions.title')}
          description={t('help.namingConventions.description')}
        >
          {examplesLinks.map((item, index) => (
            <Anchor
              tabIndex={0}
              aria-label="image examples"
              key={index}
              role="button"
              onClick={() => handleExampleClick(item.image)}
            >
              {item.text}
            </Anchor>
          ))}
        </InfoBox>
      </div>

      <PortLabelsContainer className="flex-grow-1">
        {loading ? (
          <LoadingIndicator />
        ) : namingConventions.length ? (
          <>
            <Container isAmazon={isAmazonHost()}>
              {namingConventions.map((item) => (
                <SymbolCheckbox
                  key={item.id}
                  id={item.id}
                  label={item.label}
                  defaultChecked={isSelected(item)}
                  onClick={() => handleItem(item)}
                />
              ))}
              {namingConventions.length % 3 !== 0 && <FillGrid />}
            </Container>
            {allNamingConventions.length !== namingConventions.length && (
              <Anchor tabIndex={0} role="button" onClick={handleLoadAll}>
                {t('buttons.loadAll')}
              </Anchor>
            )}
            <br />
            <ExtraSpace />
            <ArrowButton onClick={handleNoTerminals}>
              <span>{t('buttons.noTerminals')}</span>
              <img src={iconUrl.arrow} alt="arrow" />
            </ArrowButton>
          </>
        ) : (
          <span
            role="button"
            className="d-block mt-3"
            onClick={() =>
              handleTerminalsOutcome(ReasonOutcomeType.NoSearchResultsClick)
            }
          >
            <InfoBox
              isHelp
              title={t('help.noResults.title')}
              description={t('help.noResults.description')}
            ></InfoBox>
          </span>
        )}
      </PortLabelsContainer>
      <NavFooter ref={navFooter}>
        <Button
          type="button"
          disabled={!selected.length || !listModelsData}
          onClick={handleContinue}
        >
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>
      {showModal && exampleImage !== undefined && (
        <ExampleModal
          image={exampleImage}
          onClose={() => {
            setShowModal(false);
            setExampleImage(undefined);
          }}
        />
      )}
    </div>
  );
};

export default NamingConventions;
