import { createContext, useState } from 'react';
import {
  Model,
  ModelVariant,
  NamingConvention,
  SchemePreference,
} from '../api';

export interface StepperContextData {
  activeStep: number;
  multiDoor?: boolean;
  isParallel?: boolean;
  brand?: string;
  model?: Model;
  hasIntercom?: boolean;
  hasOneButton?: boolean;
  searchBy?: SchemePreference;
  terminals?: NamingConvention[];
  fallbackToModel?: boolean;
  hasBackplate?: boolean;
  variant?: ModelVariant;
}

interface StepperProviderProps {
  children?: React.ReactNode;
}

interface StepperContextProps {
  stepperData: StepperContextData;
  updateStepperData: (value: Partial<StepperContextData>) => void;
}

export const StepperContext = createContext<StepperContextProps>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  updateStepperData: () => {},
  stepperData: { activeStep: 0 },
});

export const StepperProvider = ({ children }: StepperProviderProps) => {
  const [stepperData, setStepperData] = useState<StepperContextData>({
    activeStep: 0,
  });

  const updateStepperData: StepperContextProps['updateStepperData'] = (
    partial
  ) => {
    setStepperData({ ...stepperData, ...partial });
  };

  return (
    <StepperContext.Provider value={{ stepperData, updateStepperData }}>
      {children}
    </StepperContext.Provider>
  );
};
