import { isAmazonHost, logger } from '../utils';
import { useHeap } from './useHeap';

const useMetrics = () => {
  const { track: trackHeap } = useHeap();

  const getAmzNavConfig = () => {
    if (typeof window !== 'undefined' && isAmazonHost()) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const navConfig = (window as any)?.$Nav?.getNow?.(
        'config.lightningDeals'
      );
      const customerId = navConfig?.customerID ?? null;
      return { customerId };
    }
    return { customerId: null };
  };

  const track = (
    event: string,
    properties?: Record<string, unknown> | undefined
  ) => {
    if (isAmazonHost()) {
      const { customerId } = getAmzNavConfig();
      logger.info(event, { ...properties, customerId });
    } else {
      trackHeap(event, properties);
    }
  };

  return { track };
};

export default useMetrics;
