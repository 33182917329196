import { useCallback, useContext } from 'react';
import {
  MetricProperty,
  getAbsTimeDifferenceInSeconds,
  isAmazonHost,
  metricEvents,
} from '../utils';
import { AppOutcome } from '../types';
import { useNavigate } from 'react-router-dom';
import { ModelVariant, NamingConvention } from '../api';
import { SessionContext, StepperContext } from '../contexts';
import {
  FlowStep,
  GlobalContext,
  updateFlowStep,
} from '../contexts/GlobalContext';
import useMetrics from './useMetrics';

export enum ScreenOutcomeType {
  SelectModelNumber = 'select-model-number',
  SelectTerminals = 'select-terminals',
  SelectModelsFound = 'select-model-found',
  BrandSelection = 'brand-selection',
  Question = 'question',
  SelectBackplate = 'SelectBackplate',
  HasBackplate = 'HasBackplate',
}

export enum ReasonOutcomeType {
  NoIntercom = 'no-intercom',
  MultiButton = 'multi-button',
  APIError = 'api-error',
  EarlyResult = 'early-result',
  HelpClick = 'help-click',
  TerminalsNotInList = 'terminals-not-in-list',
  NoSearchResultsClick = 'no-search-results-click',
  Complete = 'complete',
  NoBackplate = 'no-backplate',
}

interface ModelInterface {
  model_name: string;
}

export const useHandleOutcome = () => {
  const navigate = useNavigate();
  const { track } = useMetrics();
  const sessionInitTime = useContext(SessionContext);
  const { stepperData } = useContext(StepperContext);
  const { dispatch } = useContext(GlobalContext);
  const { multiDoor, isParallel } = stepperData;

  const booleanToString = (bool: boolean) => (bool ? 'true' : 'false');

  const handleOutcome = useCallback(
    ({
      value,
      screen,
      brand,
      model,
      terminals,
      variant,
      reason,
      searchText,
    }: {
      value: AppOutcome;
      screen: ScreenOutcomeType;
      reason: ReasonOutcomeType;
      brand?: string;
      model?: ModelInterface;
      terminals?: NamingConvention[];
      variant?: ModelVariant;
      searchText?: string;
    }) => {
      track(metricEvents.outcome, {
        [MetricProperty.outcomeValue]: value,
        [MetricProperty.screen]: screen,
        [MetricProperty.selectedBrand]: brand,
        [MetricProperty.selectedModel]: model?.model_name,
        [MetricProperty.multiDoor]:
          multiDoor === undefined ? undefined : booleanToString(multiDoor),
        [MetricProperty.parallel]:
          isParallel === undefined ? undefined : booleanToString(isParallel),
        [MetricProperty.terminals]: terminals?.map((el) => el).join(','),
        [MetricProperty.backplate]: variant?.backplate_number,
        [MetricProperty.reason]: reason,
        [MetricProperty.searchText]: searchText,
        ...(sessionInitTime && {
          [MetricProperty.sessionTime]: getAbsTimeDifferenceInSeconds(
            sessionInitTime,
            new Date()
          ),
        }),
      });

      if (isAmazonHost()) {
        let newFlowStep = FlowStep.compatibilityChecker;
        if (value === AppOutcome.Compatible) newFlowStep = FlowStep.success;
        if (value === AppOutcome.NotCompatible)
          newFlowStep = FlowStep.notCompatible;
        if (value === AppOutcome.InfoNeeded) newFlowStep = FlowStep.infoNeeded;
        dispatch(updateFlowStep({ flowStep: newFlowStep }));
      } else {
        navigate({
          pathname: `/${value}`,
          search: window.location.search,
        });
      }
    },
    [navigate, sessionInitTime, track, multiDoor, isParallel]
  );

  return { handleOutcome };
};
export default useHandleOutcome;
