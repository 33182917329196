import {
  Brand,
  Candidate,
  Model,
  ModelsListResponse,
  NamingConvention,
} from '../api';
import { RadioOption } from '../components/RadioGroup/RadioGroup';

export const formatTerminalLabels = (data: NamingConvention[]) => {
  return data
    .filter((term) => term.length > 0)
    .map((item, index) => {
      return { id: index.toString(), label: item };
    });
};

export const formatBrands = (data: Brand[]) => {
  return data
    .sort((a, b) => (a.brand_name > b.brand_name ? 1 : -1))
    .map((brand, index) => ({
      id: index.toString(),
      label: brand.brand_name,
      value: brand.brand_name,
    }));
};

export const formatCandidates = (data: ModelsListResponse) => {
  const uniqueMappings: RadioOption<Candidate>[] = [];

  data.candidates.forEach((candidate) => {
    const label = candidate.terminal_labels.reduce(
      (acc, curr, i) => `${acc}${i === 0 ? '' : '/'}${curr}`,
      ''
    );

    const matchingMapping = uniqueMappings.find(
      (terminalMapping) => terminalMapping.label === label
    );

    if (matchingMapping) {
      if (matchingMapping.value.support !== candidate.support) {
        matchingMapping.value.support = 'possible_support';
      }
    } else {
      uniqueMappings.push({
        id: label,
        label,
        value: candidate,
      });
    }
  });

  return uniqueMappings;
};

export const formatModels = (data: Model[]) => {
  return data
    .sort((a, b) => (a.model_name > b.model_name ? 1 : -1))
    .map((model, index) => ({
      id: index.toString(),
      label: model.model_name,
      value: model.model_name,
    }));
};
