import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  BrandSelection,
  ModelSelection,
  Models,
  Step,
  Stepper,
  PreQualificationQuestion,
  NamingConventions,
  CompatibilityOptions,
  HasBackplate,
  BackplateSelection,
  Button,
  NavFooter,
} from '../';
import { AppOutcome, StepKey } from '../../types';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import {
  BrandsContext,
  StepperContext,
  StepperContextData,
} from '../../contexts';
import { SchemePreference } from '../../api';
import {
  MetricProperty,
  delay,
  getRedirectUrl,
  isAmazonHost,
  isEmpty,
  isNil,
  metricEvents,
} from '../../utils';
import { imagesUrl } from '../../utils';
import { environment } from '../../utils/environment';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

const Wizard: FunctionComponent = () => {
  const { t } = useTranslation('common');
  const { handleOutcome } = useHandleOutcome();
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brands } = useContext(BrandsContext);
  const [forceV2, setForceV2] = useState(false);
  const [localStepperData, setLocalStepperData] = useState<
    Partial<StepperContextData>
  >({});
  const { track } = useMetrics();
  const {
    hasIntercom,
    isParallel,
    searchBy,
    hasOneButton,
    multiDoor,
    activeStep,
    fallbackToModel,
    hasBackplate,
    brand,
  } = stepperData;

  const stepWithFooter = () =>
    [
      StepKey.BackplateSelection,
      StepKey.FindBrand,
      StepKey.HasBackplate,
      StepKey.Models,
      StepKey.FindModel,
      StepKey.NamingConventions,
    ].includes(steps[stepperData.activeStep].key as StepKey);

  const handleStep = async (
    customPos?: number,
    customData?: Partial<StepperContextData>
  ) => {
    const newStepperData = {
      ...customData,
      activeStep: customPos ?? activeStep + 1,
    };

    updateStepperData({ ...customData });
    await delay(250);
    updateStepperData({ ...newStepperData });
  };

  const handleBack = () => {
    if (activeStep <= 0) {
      return;
    }

    const step = steps[activeStep];
    let customPos = activeStep - 1;

    // skip hasMultiButton step if it was skipped when navigating forward
    if (step.key === StepKey.FindBrand) {
      if (!multiDoor) {
        customPos = activeStep - 3;
      }

      if (multiDoor && isParallel) {
        customPos = activeStep - 2;
      }
    }

    // skip compatibilityOptions step if skipped when navigating forward
    if (
      [StepKey.FindModel, StepKey.NamingConventions].includes(
        step.key as StepKey
      )
    ) {
      const selectedBrand = brands?.find((obj) => obj.brand_name === brand);
      if (isEmpty(selectedBrand?.terminal_labels)) {
        customPos = activeStep - 2;
      }
    }

    // clear data when navigate back
    const customData: StepperContextData = {
      ...stepperData,
      ...(step.key === StepKey.FindModel && {
        model: undefined,
      }),
      ...(step.key === StepKey.FindBrand && {
        brand: undefined,
      }),
      ...(step.key === StepKey.IsParallel && {
        isParallel: undefined,
      }),
      ...(step.key === StepKey.HasMultipleDoors && {
        multiDoor: undefined,
        isParallel: undefined,
      }),
      ...(step.key === StepKey.CompatibilityOptions && {
        searchBy: undefined,
      }),
      ...(step.key === StepKey.HasOneButton && {
        hasOneButton: undefined,
      }),
      ...(step.key === StepKey.BackplateSelection && {
        variant: undefined,
      }),
    };

    updateStepperData({
      ...customData,
      activeStep: customPos,
    });
    if (isAmazonHost()) {
      setLocalStepperData({
        ...customData,
        activeStep,
      });
    }
  };

  const handleContinue = () => {
    if (stepperData.hasIntercom === false) {
      handleOutcome({
        value: AppOutcome.NotCompatible,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.NoIntercom,
      });
    } else if (stepperData.hasOneButton === false) {
      handleOutcome({
        value: AppOutcome.NotCompatible,
        screen: ScreenOutcomeType.Question,
        reason: ReasonOutcomeType.MultiButton,
      });
    } else {
      updateStepperData({ ...localStepperData });
    }
  };

  const shouldDisableCTA = () => {
    const step = steps[activeStep];
    if (
      (step?.key === StepKey.HasIntercom && isNil(stepperData.hasIntercom)) ||
      (step?.key === StepKey.HasMultipleDoors &&
        isNil(stepperData.multiDoor)) ||
      (step?.key === StepKey.IsParallel && isNil(stepperData.isParallel)) ||
      (step?.key === StepKey.HasOneButton && isNil(stepperData.hasOneButton))
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (getRedirectUrl()) {
      setForceV2(true);
    }
  }, []);

  const findModelSteps = [
    <Step
      key={StepKey.FindModel}
      stepKey={StepKey.FindModel}
      image={{ source: imagesUrl.find_model, altText: t('images.findModel') }}
      preferHuman
    >
      <ModelSelection
        next={() => {
          handleStep(activeStep + 1, { hasBackplate: true });
        }}
        onBack={handleBack}
      />
    </Step>,
    ...(hasBackplate
      ? [
          <Step
            key={StepKey.HasBackplate}
            stepKey={StepKey.HasBackplate}
            image={{
              source: imagesUrl.backplate,
              altText: t('images.backplate'),
            }}
            preferHuman
          >
            <HasBackplate
              next={() => handleStep(undefined, undefined)}
              onBack={handleBack}
            />
          </Step>,
          <Step
            key={StepKey.BackplateSelection}
            stepKey={StepKey.BackplateSelection}
            image={{
              source: imagesUrl.backplate,
              altText: t('images.backplate'),
            }}
            preferHuman
          >
            <BackplateSelection onBack={handleBack} />
          </Step>,
        ]
      : []),
  ];

  const steps = [
    <Step key={StepKey.HasIntercom} stepKey={StepKey.HasIntercom}>
      <PreQualificationQuestion
        question={t('prompts.hasIntercom')}
        value={hasIntercom}
        groupName="hasIntercom"
        yesAction={() => handleStep(activeStep + 1, { hasIntercom: true })}
        noAction={() => {
          updateStepperData({ hasIntercom: false });
          handleOutcome({
            value: AppOutcome.NotCompatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.NoIntercom,
          });
        }}
      />
    </Step>,

    <Step key={StepKey.HasMultipleDoors} stepKey={StepKey.HasMultipleDoors}>
      <PreQualificationQuestion
        question={t('prompts.hasMultipleDoors')}
        value={multiDoor}
        groupName="hasMultipleDoors"
        yesAction={() => {
          handleStep(activeStep + 1, {
            multiDoor: true,
            isParallel: undefined,
          });
        }}
        noAction={() => {
          handleStep(activeStep + 3, { multiDoor: false, isParallel: false });
        }}
        yesText={t('responses.q3Yes')}
        noText={t('responses.q3No')}
      />
    </Step>,

    <Step key={StepKey.IsParallel} stepKey={StepKey.IsParallel}>
      <PreQualificationQuestion
        question={t('prompts.isParallel')}
        value={isParallel}
        groupName="isParallel"
        yesAction={() => handleStep(activeStep + 2, { isParallel: true })}
        noAction={() => handleStep(activeStep + 1, { isParallel: false })}
        yesText={t('responses.q4Yes')}
        noText={t('responses.q4No')}
      />
    </Step>,

    <Step key={StepKey.HasOneButton} stepKey={StepKey.HasOneButton}>
      <PreQualificationQuestion
        question={t('prompts.hasOneButton')}
        value={hasOneButton}
        groupName="hasOneButton"
        yesAction={() => handleStep(activeStep + 1, { hasOneButton: true })}
        noAction={() => {
          updateStepperData({ hasOneButton: false });
          handleOutcome({
            value: AppOutcome.NotCompatible,
            screen: ScreenOutcomeType.Question,
            reason: ReasonOutcomeType.MultiButton,
          });
        }}
        yesText={t('responses.q5Yes')}
        noText={t('responses.q5No')}
      />
    </Step>,

    <Step
      key={StepKey.FindBrand}
      stepKey={StepKey.FindBrand}
      image={{ source: imagesUrl.find_brand, altText: t('images.findBrand') }}
      preferHuman
    >
      <BrandSelection
        next={(shouldSkipOptions) => {
          // if no terminal labels, redirect straight to model selection
          const customPos = shouldSkipOptions ? activeStep + 2 : undefined;
          const customData = shouldSkipOptions
            ? { searchBy: SchemePreference.ModelName }
            : undefined;
          if (customPos !== undefined) {
            track(metricEvents.searchPreferenceSkipped, {
              [MetricProperty.searchPreference]: SchemePreference.ModelName,
              [MetricProperty.selectedBrand]: brand,
            });
          }
          handleStep(customPos, customData);
        }}
        onBack={handleBack}
      />
    </Step>,

    ...(forceV2 && environment.v2Weblab
      ? [...findModelSteps]
      : [
          <Step
            key={StepKey.CompatibilityOptions}
            stepKey={StepKey.CompatibilityOptions}
          >
            <CompatibilityOptions
              onSelect={(val) => {
                track(metricEvents.searchPreference, {
                  [MetricProperty.searchPreference]: val,
                  [MetricProperty.selectedBrand]: brand,
                });
                handleStep(undefined, { searchBy: val });
              }}
            />
          </Step>,

          ...(searchBy === SchemePreference.ModelName
            ? [...findModelSteps]
            : [
                <Step
                  key={StepKey.NamingConventions}
                  stepKey={StepKey.NamingConventions}
                  image={{
                    source: imagesUrl.find_model,
                    altText: t('images.findModel'),
                  }}
                  preferHuman
                >
                  <NamingConventions
                    next={(customData) => handleStep(undefined, customData)}
                    onBack={handleBack}
                  />
                </Step>,
                <Step
                  key={StepKey.Models}
                  stepKey={StepKey.Models}
                  image={{
                    source: imagesUrl.find_model,
                    altText: t('images.findModel'),
                  }}
                  preferHuman
                >
                  <Models
                    next={(customData) => handleStep(undefined, customData)}
                    onBack={handleBack}
                  />
                </Step>,
                ...(fallbackToModel ? [...findModelSteps] : []),
              ]),
        ]),
  ];

  useEffect(() => {
    const step = steps[activeStep];
    track(metricEvents.pageView, {
      [MetricProperty.pageName]: step.key,
    });
  }, [activeStep]);

  return (
    <>
      <Stepper
        headerTitle={t('header.title')}
        hasBackButton={activeStep !== 0}
        activeStep={activeStep}
        showStepIndicator
        onBack={handleBack}
      >
        {steps}
      </Stepper>
      {isAmazonHost() && !stepWithFooter() && (
        <NavFooter xPadding={36}>
          {![StepKey.CompatibilityOptions].includes(
            steps[stepperData.activeStep].key as StepKey
          ) && (
            <Button
              type="button"
              onClick={handleContinue}
              disabled={shouldDisableCTA()}
            >
              {t('buttons.continue')}
            </Button>
          )}
          {![StepKey.HasIntercom].includes(
            steps[stepperData.activeStep].key as StepKey
          ) && (
            <Button type="button" secondary onClick={handleBack}>
              {t('header.back')}
            </Button>
          )}
        </NavFooter>
      )}
    </>
  );
};

export default Wizard;
