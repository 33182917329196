import { useTranslation } from 'react-i18next';
import { Anchor, Question } from '../../App.styles';
import { FunctionComponent, useState } from 'react';
import InfoBox from '../InfoBox/InfoBox';
import Card from '../Card/Card';
import ShowMeModal from '../ShowMeModal/ShowMeModal';
import {
  Cards,
  Tag,
  CardData,
  MainContainer,
} from './CompatibilityOptions.styles';
import { SchemePreference } from '../../api';
import { useMetrics, useSelectedBrand } from '../../hooks';
import {
  MetricProperty,
  TestId,
  imagesUrl,
  isAmazonHost,
  metricEvents,
} from '../../utils';
import { StepKey } from '../../types';

interface CompatibilityOptionsProps {
  onSelect: (opt: SchemePreference) => void;
}

const CompatibilityOptions: FunctionComponent<CompatibilityOptionsProps> = ({
  onSelect,
}) => {
  const { t } = useTranslation('common');
  const { track } = useMetrics();
  const brand = useSelectedBrand();
  const [showModal, setShowModal] = useState(false);

  const isRecommendedOption = (type: SchemePreference) => {
    if (brand && brand.identification_scheme_preference === type) {
      return true;
    }
    return false;
  };

  const getExamples = () => {
    return brand?.popular_models?.join(', ');
  };

  const handleShowMe = () => {
    setShowModal(true);
    track(metricEvents.showMeBtn, {
      [MetricProperty.pageName]: StepKey.CompatibilityOptions,
    });
  };

  const cardsData = [
    {
      withTag: isRecommendedOption(SchemePreference.ModelName),
      type: SchemePreference.ModelName,
      title: t('compatibilityOptions.cardModel.title'),
      description: t('compatibilityOptions.cardModel.description', {
        examples: getExamples(),
      }),
      image: imagesUrl.backplate,
    },
    {
      withTag: isRecommendedOption(SchemePreference.TerminalLabels),
      type: SchemePreference.TerminalLabels,
      title: t('compatibilityOptions.cardTerminals.title'),
      description: t('compatibilityOptions.cardTerminals.description'),
      image: imagesUrl.port_labels,
    },
  ].sort((x, y) => (x.withTag === y.withTag ? 0 : x.withTag ? -1 : 1));

  return (
    <MainContainer
      className="row h-100 flex-column"
      isAmazon={isAmazonHost()}
      data-testid={TestId.CompatibilityOptions}
    >
      <Question className="col-12">
        {t('prompts.selectCompatibilityOption')}
      </Question>

      <div className="col-12 my-3">
        <InfoBox title={t('help.removeCover.title')}>
          <Anchor tabIndex={0} role="button" onClick={handleShowMe}>
            {t('help.removeCover.showMe')}
          </Anchor>
        </InfoBox>
      </div>

      <Cards className="col-12 flex-row">
        {cardsData.map((card, idx) => (
          <Card
            key={idx}
            onClick={() => onSelect(card.type)}
            image={card.image}
            imageAlt={card.type}
            content={
              <CardData>
                <h4>{card.title}</h4>
                <p>{card.description}</p>
                {card.withTag && <Tag>{t('compatibilityOptions.tag')}</Tag>}
              </CardData>
            }
          />
        ))}
      </Cards>
      {showModal && (
        <ShowMeModal closeModal={() => setShowModal(false)}></ShowMeModal>
      )}
    </MainContainer>
  );
};

export default CompatibilityOptions;
