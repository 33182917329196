import {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Model, getModels } from '../../api';
import {
  Anchor,
  ErrorMessage,
  Input,
  InputClearButton,
  InputContainer,
  InputStickyContainer,
  Question,
} from '../../App.styles';
import Button from '../Button';
import InfoBox from '../InfoBox/InfoBox';
import NavFooter from '../NavFooter/NavFooter';
import RadioGroup, { RadioOption } from '../RadioGroup/RadioGroup';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { useTranslation } from 'react-i18next';
import ShowMeModal from '../ShowMeModal/ShowMeModal';
import { ScreenOutcomeType, useHandleOutcome, useMetrics } from '../../hooks';
import {
  MetricProperty,
  TestId,
  debounce,
  formatModels,
  getModelOutcome,
  getSearchResult,
  iconUrl,
  isAmazonHost,
  metricEvents,
} from '../../utils';
import { AppOutcome, StepKey } from '../../types';
import { StepperContext } from '../../contexts';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface Props {
  next: () => void;
  onBack: () => void;
}

const ModelSelection: FunctionComponent<Props> = ({ next, onBack }) => {
  const [models, setModels] = useState([] as RadioOption<string>[]);
  const [allModels, setAllModels] = useState([] as RadioOption<string>[]);
  const [allModelData, setAllModelData] = useState([] as Model[]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const { t } = useTranslation('common');
  const { track } = useMetrics();
  const { handleOutcome } = useHandleOutcome();
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { multiDoor, isParallel, brand, model } = stepperData;

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const data = await getModels(
        brand as string,
        multiDoor as boolean,
        isParallel as boolean
      );
      setAllModelData(data);
      const tempModels = formatModels(data);

      setAllModels(tempModels);
      setModels(tempModels);

      setLoading(false);
    };
    if (
      brand !== undefined &&
      multiDoor !== undefined &&
      isParallel !== undefined &&
      !allModels.length
    ) {
      getData();
    }
  }, [brand, multiDoor, allModels, isParallel]);

  useEffect(() => {
    if (model) {
      setFilter(model.model_name);
    }
  }, [setFilter]);

  useEffect(() => {
    const filteredModels = getSearchResult(allModels, 'label', filter);
    setModels(filteredModels);
    if (filteredModels.length === 0) {
      debouncedSendModelNotFound(filter);
    }
  }, [filter, allModels, setModels]);

  useEffect(() => {
    if (model) {
      track(metricEvents.selectedModel, {
        [MetricProperty.selectedModel]: model.model_name,
        [MetricProperty.selectedBrand]: brand,
        [MetricProperty.multiDoor]: multiDoor ? 'true' : 'false',
        [MetricProperty.parallel]: isParallel ? 'true' : 'false',
      });
    }
  }, [brand, model, multiDoor, isParallel, track]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSendModelNotFound = useCallback(
    debounce((enteredValue: string) => {
      track(metricEvents.modelNotFound, {
        [MetricProperty.enteredModelText]: enteredValue,
        [MetricProperty.selectedBrand]: brand,
        [MetricProperty.multiDoor]: multiDoor ? 'true' : 'false',
        [MetricProperty.parallel]: isParallel ? 'true' : 'false',
      });
    }, 2000),
    []
  );

  const handleModelSelectionOutcome = (
    outcome: AppOutcome,
    reason: ReasonOutcomeType,
    searchText?: string
  ) => {
    handleOutcome({
      value: outcome,
      screen: ScreenOutcomeType.SelectModelNumber,
      brand,
      model,
      variant: model?.variants[0],
      reason,
      searchText,
    });
  };

  const handleShowMe = () => {
    setShowModal(true);
    track(metricEvents.showMeBtn, {
      [MetricProperty.pageName]: StepKey.FindModel,
    });
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.FindModel,
    });
    handleModelSelectionOutcome(
      AppOutcome.InfoNeeded,
      ReasonOutcomeType.HelpClick
    );
  };

  return (
    <div className="row h-100 flex-column" data-testid={TestId.ModelSelection}>
      <Question className="col-12">
        {t('prompts.whatModel', { brand: brand })}
      </Question>

      <div className="col-12 mt-3">
        <InfoBox
          title={t('help.removeCover.title')}
          description={t('help.removeCover.description')}
        >
          <Anchor tabIndex={0} role="button" onClick={handleShowMe}>
            {t('help.removeCover.showMe')}
          </Anchor>
        </InfoBox>
      </div>

      <InputStickyContainer className="col-12 py-3" sticky background="white">
        <InputContainer>
          {filter.length > 0 && (
            <InputClearButton
              aria-label="close"
              onClick={() => {
                setFilter('');
                updateStepperData({ model: undefined });
              }}
            >
              <img src={iconUrl.close} alt={t('textFields.closeBtn')} />
            </InputClearButton>
          )}
          <Input
            type="text"
            hasError={!loading && !models.length}
            placeholder={t('placeholders.modelFilter')}
            className="form-control"
            value={filter}
            onChange={(e) => {
              updateStepperData({ model: undefined });
              setFilter(e.target.value);
            }}
          />
        </InputContainer>
        {!loading && !models.length && (
          <ErrorMessage className="mt-3">
            {t('help.noResults.errorMessage')}
          </ErrorMessage>
        )}
      </InputStickyContainer>

      <div className="flex-grow-1">
        {loading ? (
          <LoadingIndicator></LoadingIndicator>
        ) : models.length ? (
          <RadioGroup
            groupName="model"
            options={models}
            value={model?.model_name}
            setValue={(val) => {
              const modelFound = allModelData.find(
                (model) => model.model_name === val
              );
              updateStepperData({ model: modelFound });
            }}
          ></RadioGroup>
        ) : (
          <span
            role="button"
            className="d-block mt-3"
            onClick={() => {
              const searchText = filter.toLowerCase().trim();
              handleModelSelectionOutcome(
                AppOutcome.InfoNeeded,
                ReasonOutcomeType.NoSearchResultsClick,
                searchText
              );
            }}
          >
            <InfoBox
              isHelp
              title={t('help.noResults.title')}
              description={t('help.noResults.description')}
            ></InfoBox>
          </span>
        )}
      </div>

      <NavFooter>
        <Button
          type="button"
          disabled={!model}
          onClick={() => {
            if (model) {
              if (model.support) {
                handleModelSelectionOutcome(
                  getModelOutcome(model.support),
                  ReasonOutcomeType.Complete
                );
              } else {
                next();
              }
            }
          }}
        >
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>

      {showModal ? (
        <ShowMeModal closeModal={() => setShowModal(false)}></ShowMeModal>
      ) : null}
    </div>
  );
};

export default ModelSelection;
