import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import Result from '../../components/Result/Result';
import { Question } from '../../App.styles';
import { ModelBrand, ResultInfo } from '../../components/Result/Result.styles';
import { Button, NavFooter } from '../../components';
import { usePdpUrl, useTimeSpentMetric } from '../../hooks';
import InfoBox from '../../components/InfoBox/InfoBox';
import {
  TestId,
  closeAmzModal,
  iconUrl,
  imagesUrl,
  isAmazonHost,
} from '../../utils';
import {
  FlowStep,
  GlobalContext,
  StepperContext,
  updateFlowStep,
} from '../../contexts';

const Success: FunctionComponent = () => {
  const { dispatch } = React.useContext(GlobalContext);
  const { stepperData } = React.useContext(StepperContext);
  const { brand, model, variant } = stepperData
    ? stepperData
    : { brand: undefined, model: undefined, variant: undefined };
  const { t } = useTranslation('common');
  const pdpUrl = usePdpUrl();

  useTimeSpentMetric('success');

  return (
    <Result image={{ source: imagesUrl.success, altText: t('images.success') }}>
      <div className="row h-100 flex-column" data-testid={TestId.Success}>
        <div className="flex-grow-1">
          <div className="mt-2 mt-md-4">
            <img src={iconUrl.check} alt="success" />
          </div>

          <Question className="my-3">{t('results.success.title')}</Question>

          <ResultInfo>
            <span>{t('results.success.description')}</span>
            {brand && model ? (
              <span>
                :
                <ModelBrand>
                  {brand}, {model.model_name}.
                </ModelBrand>
              </span>
            ) : (
              <span>.</span>
            )}
          </ResultInfo>

          <ResultInfo>
            <span>{t('results.success.videoDisclaimer')}</span>
          </ResultInfo>

          {variant?.ring_to_open && (
            <InfoBox
              title={t('results.success.r2oTitle')}
              description={t('results.success.r2oDescription')}
            />
          )}
        </div>

        <NavFooter>
          {isAmazonHost() ? (
            <>
              <Button type="button" onClick={closeAmzModal}>
                {t('header.close')}
              </Button>
              <Button
                type="button"
                ghost
                onClick={() => {
                  dispatch(
                    updateFlowStep({ flowStep: FlowStep.compatibilityChecker })
                  );
                }}
              >
                {t('header.back')}
              </Button>
            </>
          ) : (
            <a href={pdpUrl}>
              <Button type="button" className="w-100">
                {t('results.shopIntercom')}
              </Button>
            </a>
          )}
        </NavFooter>
      </div>
    </Result>
  );
};

export default Success;
