import {
  FunctionComponent,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Brand, getBrands } from '../../api';
import {
  ErrorMessage,
  Input,
  Question,
  InputStickyContainer,
  InputContainer,
  InputClearButton,
} from '../../App.styles';
import { useMetrics, useHandleOutcome, ScreenOutcomeType } from '../../hooks';
import {
  MetricProperty,
  TestId,
  debounce,
  formatBrands,
  getSearchResult,
  iconUrl,
  isAmazonHost,
  isEmpty,
  metricEvents,
} from '../../utils';
import { Button, InfoBox, LoadingIndicator, NavFooter, RadioGroup } from '../';
import { RadioOption } from '../RadioGroup/RadioGroup';
import { AppOutcome, StepKey } from '../../types';
import { BrandsContext, StepperContext } from '../../contexts';
import { ReasonOutcomeType } from '../../hooks/useHandleOutcome';

interface Props {
  next: (shouldSkipOptions: boolean) => void;
  onBack: () => void;
}

const BrandSelection: FunctionComponent<Props> = ({ next, onBack }) => {
  const [brands, setBrands] = useState([] as RadioOption<string>[]);
  const [allBrands, setAllBrands] = useState([] as RadioOption<string>[]);
  const [selectedBrand, setSelectedBrand] = useState<Brand | null>(null);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const { track } = useMetrics();
  const { t } = useTranslation('common');
  const navFooter = useRef<HTMLDivElement>(null);
  const { handleOutcome } = useHandleOutcome();
  const { brands: brandsUnaltered, updateBrands } = useContext(BrandsContext);
  const { stepperData, updateStepperData } = useContext(StepperContext);
  const { brand } = stepperData;

  useEffect(() => {
    const requestBrands = async () => {
      getBrands()
        .then((data) => {
          const tempBrands = formatBrands(data);
          setAllBrands(tempBrands);
          setBrands(tempBrands);
          if (data && updateBrands) {
            updateBrands(data);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    };
    if (updateBrands && !allBrands.length) {
      requestBrands();
    }
  }, [updateBrands, allBrands]);

  useEffect(() => {
    // when navigating backwards brand (from stepperData) has a value
    // but local state `selectedBrand` doesn't, so updating it here
    if (!isEmpty(brand) && isEmpty(selectedBrand)) {
      setSelectedBrand(
        brandsUnaltered?.find((obj) => obj.brand_name === brand) ?? null
      );
    }
  }, [brand, selectedBrand]);

  useEffect(() => {
    if (brand) {
      setFilter(brand);
    }
  }, [setFilter]);

  useEffect(() => {
    const filteredBrands = getSearchResult(allBrands, 'label', filter);
    setBrands(filteredBrands);
    if (filteredBrands.length === 0) {
      debouncedSendBrandNotFound(filter);
    }
  }, [filter, allBrands, setBrands]);

  const handleSelectedBrand = (value: string) => {
    track(metricEvents.selectedBrand, {
      [MetricProperty.selectedBrand]: value,
    });
    updateStepperData({ brand: value, model: undefined });
    setSelectedBrand(
      brandsUnaltered?.find((obj) => obj.brand_name === value) ?? null
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSendBrandNotFound = useCallback(
    debounce((enteredValue: string) => {
      track(metricEvents.brandNotFound, {
        [MetricProperty.enteredBrandText]: enteredValue,
      });
    }, 2000),
    []
  );

  const handleClick = (reason: ReasonOutcomeType, searchText?: string) => {
    handleOutcome({
      value: AppOutcome.InfoNeeded,
      screen: ScreenOutcomeType.BrandSelection,
      reason,
      brand,
      searchText,
    });
  };

  const handleNeedHelp = () => {
    track(metricEvents.needHelpBtn, {
      [MetricProperty.pageName]: StepKey.FindBrand,
    });
    handleClick(ReasonOutcomeType.HelpClick);
  };

  return (
    <div className="row h-100 flex-column" data-testid={TestId.BrandSelection}>
      <Question className="col-12">{t('prompts.whatBrand')}</Question>
      <div className="col-12 mt-3">
        <InfoBox
          title={t('help.whereToFind.title')}
          description={t('help.whereToFind.description')}
        />
      </div>
      <InputStickyContainer className="col-12 py-3" sticky background="white">
        <InputContainer>
          {filter.length > 0 && (
            <InputClearButton
              aria-label="close"
              onClick={() => {
                setFilter('');
                updateStepperData({ brand: undefined });
              }}
            >
              <img src={iconUrl.close} alt={t('textFields.closeBtn')} />
            </InputClearButton>
          )}
          <Input
            type="text"
            hasError={!loading && !brands.length}
            placeholder={t('placeholders.brandFilter')}
            className="form-control"
            onFocus={(e) => {
              setTimeout(() => {
                const stepContent = document.getElementById('step-content');
                if (stepContent && navFooter.current) {
                  const scrollPosition = stepContent.scrollTop;
                  const scrollHeight = stepContent.clientHeight;
                  const bottomNavHeight = navFooter.current.clientHeight;
                  const navTop = scrollHeight - bottomNavHeight;
                  const relativeDistance = e.target.offsetTop - 66; //accounting for header
                  const inputHeight = e.target.clientHeight;
                  const isHidden =
                    relativeDistance + inputHeight - scrollPosition > navTop;
                  if (isHidden) {
                    stepContent.scrollTo({
                      top: relativeDistance - 15,
                      behavior: 'smooth',
                    });
                  }
                }
              }, 250);
            }}
            value={filter}
            onChange={(e) => {
              updateStepperData({ brand: undefined });
              setFilter(e.target.value);
            }}
          />
        </InputContainer>
        {!loading && !brands.length && (
          <ErrorMessage className="mt-3">
            {t('help.noResults.errorMessage')}
          </ErrorMessage>
        )}
      </InputStickyContainer>
      <div className="flex-grow-1">
        {loading ? (
          <LoadingIndicator />
        ) : brands.length ? (
          <RadioGroup
            groupName="brand"
            options={brands}
            value={brand as string}
            onChange={handleSelectedBrand}
          />
        ) : (
          <span
            role="button"
            className="d-block mt-3"
            onClick={() => {
              const searchText = filter.toLowerCase().trim();
              handleClick(ReasonOutcomeType.NoSearchResultsClick, searchText);
            }}
          >
            <InfoBox
              isHelp
              title={t('help.noResults.title')}
              description={t('help.noResults.description')}
            />
          </span>
        )}
      </div>
      <NavFooter ref={navFooter}>
        <Button
          type="button"
          disabled={!brand}
          onClick={() => next(isEmpty(selectedBrand?.terminal_labels))}
        >
          {t('buttons.continue')}
        </Button>
        {isAmazonHost() && (
          <>
            <Button type="button" ghost onClick={onBack}>
              {t('header.back')}
            </Button>
            <div style={{ marginRight: 'auto' }} />
          </>
        )}
        <Button type="button" ghost onClick={handleNeedHelp}>
          {t('buttons.needHelp')}
        </Button>
      </NavFooter>
    </div>
  );
};

export default BrandSelection;
