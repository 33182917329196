import { createContext, useState } from 'react';
import { ModelsListResponse } from '../api';

interface CandidateModelsProviderProps {
  children?: React.ReactNode;
}

interface CandidateModelsProps {
  data?: ModelsListResponse;
  updateData?: (value?: ModelsListResponse) => void;
}

export const CandidateModelsContext = createContext<CandidateModelsProps>({});

export const CandidateModelsProvider = ({
  children,
}: CandidateModelsProviderProps) => {
  const [data, setData] = useState<ModelsListResponse>();

  const updateData = (values: ModelsListResponse | undefined) => {
    setData(values);
  };

  return (
    <CandidateModelsContext.Provider value={{ data, updateData }}>
      {children}
    </CandidateModelsContext.Provider>
  );
};
